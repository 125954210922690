<template>
    <div class="hamburger">
        <div id="nav-overlay" :class="{ open: menuOpen }"></div>
        <nav id="nav-fullscreen" :class="{ open: menuOpen }">
            <div class="list-container">
                <q-list class="list">
                    <q-item
                        v-for="link in menuLinks"
                        :key="link.path"
                        v-ripple
                        clickable
                        :class="{ active: $route.path === link.path }"
                    >
                        <q-item-section class="text-left text-h4">
                            <NuxtLink
                                class="link text-capitalize text-h4"
                                :to="link.path"
                            >
                                {{ link.label }}
                            </NuxtLink>
                        </q-item-section>
                    </q-item>
                    <q-item
                        v-ripple
                        clickable
                        class="text-h4"
                        @click.prevent="onLogout"
                    >
                        <q-item-section class="text-left link"
                            >Logout</q-item-section
                        >
                    </q-item>
                </q-list>
                <hr class="separator" />
                <q-list class="secondary-list">
                    <q-item
                        v-for="link in secondaryMenuLinks"
                        :key="link.url"
                        v-ripple
                        clickable
                    >
                        <a
                            class="link text-h6"
                            target="_blank"
                            :href="link.url"
                        >
                            {{ link.label }}
                        </a>
                    </q-item>
                </q-list>
            </div>
        </nav>

        <a id="nav-toggle" :class="{ open: menuOpen }" @click="$emit('toggle')">
            <span></span>
            <span></span>
            <span></span>
        </a>
    </div>
</template>

<script setup lang="ts">
//import { useStore } from '@/store/store';
import { useAuthStore } from '@/store/auth';

import { useQuasar } from 'quasar';
import txtLink from '~/public/text/link';

const $q = useQuasar();
//const store = useStore();
const authStore = useAuthStore();
const links = ref(txtLink);
/* let theme = ref('myCustomLightTheme');
if (typeof Storage !== 'undefined') {
    theme.value = localStorage.getItem('appTheme') || 'myCustomLightTheme';
} */

const menuLinks = computed(() => {
    if (!authStore.isSubscription) {
        return [];
    }
    let newLinks = [
        {
            path: links.value.starter.ghostStart,
            label: 'Home',
        },
    ];
    if (!isSharedAccount.value) {
        newLinks = [
            ...newLinks,
            {
                path: links.value.starter.collections,
                label: 'Collections',
            },
            {
                path: links.value.starter.myaccount,
                label: 'Account & Referrals',
            },
        ];
    }
    return newLinks;
});

const secondaryMenuLinks = [
    {
        label: 'Need a more accessible site? Tell us here.',
        url: 'https://tally.so/r/mBp5Ee',
    },
    {
        label: 'Send us feedback or feature requests!',
        url: 'https://tally.so/r/nP9yR1',
    },
    { label: 'Artist Submission Form', url: 'https://tally.so/r/n07K2N' },
];

defineProps({
    menuOpen: {
        type: Boolean,
        required: true,
    },
});
defineEmits(['toggle']);

const dark = ref(true); //changed from false

const isSharedAccount = computed(
    () => authStore.userData?.isSharedAccount ?? false,
);

onMounted(() => {
    dark.value = true;
    $q.dark.set(true);
    //dark.value = localStorage.getItem('isDarkMode') === 'true';
    //$q.dark.set(dark.value);
   // store.changeTheme(localStorage.getItem('appTheme'));

    resizeNav();
    window.addEventListener('resize', () => {
        resizeNav();
    });
});

const resizeNav = () => {
    const navOverlay = document.querySelector('#nav-overlay');
    const navFullscreen = document.querySelector('#nav-fullscreen');
    if (!navOverlay) return;
    // Set the nav height to fill the window
    navFullscreen.style.height = window.innerHeight + 'px';

    // Set the circle radius to the length of the window diagonal,
    // this way we're only making the circle as big as it needs to be.
    var radius = Math.sqrt(
        Math.pow(window.innerHeight, 2) + Math.pow(window.innerWidth, 2),
    );
    var diameter = radius * 2;
    // $("#nav-overlay").width(diameter);
    // $("#nav-overlay").height(diameter);
    navOverlay.style.width = diameter + 'px';
    navOverlay.style.height = diameter + 'px';
    navOverlay.style.marginTop = -radius + 'px';
    navOverlay.style.marginLeft = -radius + 'px';
};

const onLogout = () => {
    authStore.logout();
};
</script>

<style lang="scss" scoped>
@import '/public/assets/variables';
$toggle-size: 32px;
$bar-size: calc($toggle-size / 8);
$bar-space: calc(($toggle-size - calc($bar-size * 5)) / 2);

$bar2-top: $bar-size + $bar-space;
$bar3-top: $bar2-top * 2;

.q-header .q-btn.active,
.q-header .q-item.active {
    color: var(--q-secondary-lighten-1);
    border-bottom-right-radius: 0%;
    border-bottom-left-radius: 0%;
    .link {
        color: var(--q-secondary-lighten-1);
    }
}

.q-item > .q-item__section {
    transition: all 0.5s ease;
}
.q-item:hover {
    .q-item__section {
        color: var(--q-secondary-lighten-1);
    }
    .link {
        padding-left: 1em;
        padding-right: 1em;
        color: var(--q-secondary-lighten-1);
    }
}

.link {
    transition: all 0.5s ease;
    color: white;
    text-decoration: none;
    text-align: left;
}

@media (max-height: 650px), (max-width: 400px) {
    .link {
        font-size: 1.3rem;
    }
    .secondary-list .link {
        font-size: 1.1rem;
    }
    .q-item {
        padding: 0 10px;
        min-height: unset;
    }
}

.hamburger {
    margin-left: auto;
    position: relative;
}

#nav-overlay {
    position: absolute;
    top: calc($toggle-size / 2);
    left: calc($toggle-size / 2);

    z-index: 1;

    background: #000;

    border-radius: 50%;

    transition: 1s;
    transform: scale(0);
    &.open {
        transform: scale(1);
    }
}

#nav-fullscreen {
    width: 100%;

    position: fixed;
    left: 0;
    top: 0;
    padding: 70px 0 50px;
    @media (min-height: 600px) {
        padding: 120px 0 50px;
    }

    transition: ease-in-out 0.25s;
    transition-delay: 0s;

    visibility: hidden;
    opacity: 0;

    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    font-size: 10vh;

    ul {
        list-style: none;
    }

    li:hover {
        list-style: disc;
    }

    &.open {
        visibility: visible;
        opacity: 1;
        transition: ease-in-out 0.5s;
        transition-delay: 0.25s;
    }
}
.list-container {
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    @media (min-width: $breakpoint-medium) {
        width: 50%;
        margin-left: auto;
    }
}
.list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0 10px;
}

.separator {
    background-color: white;
    width: 480px;
    max-width: 100%;
    margin-right: auto;
    margin-left: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 3px;
    border: none;
    @media (min-height: 650px) and (min-width: 400px) {
        margin-top: 100px;
        margin-bottom: 20px;
    }
}

.secondary-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px;
}

#nav-toggle {
    display: block;
    width: $toggle-size;
    height: $toggle-size;

    cursor: pointer;

    transform: rotate(0deg);

    position: relative;

    z-index: 2;

    span {
        background-color: var(--q-secondary-lighten-1);
        width: 100%;
        height: calc($bar-size - 1px);

        border-radius: calc($bar-size / 4);

        display: block;
        position: absolute;
        left: 0;
        content: '';

        // transition time and time function
        transition: 0.5s ease-in-out;
        transform-origin: left center;

        &:nth-child(1) {
            top: $bar-size;
        }
        &:nth-child(2) {
            top: $bar2-top + $bar-size;
        }
        &:nth-child(3) {
            top: $bar3-top + $bar-size;
        }
    }

    &.open {
        span {
            background-color: #fff;
            &:nth-child(1) {
                transform: rotate(45deg);
                top: $bar-size - calc($toggle-size / 32);
                left: $bar-size;
            }
            &:nth-child(2) {
                transform: scale(0);
                opacity: 0;
            }
            &:nth-child(3) {
                transform: rotate(-45deg);
                top: $bar3-top + calc($toggle-size / 32) + $bar-size;
                left: $bar-size;
            }
        }
    }
}
</style>
